import { cx } from "@linaria/core";
import { mergeProps, splitProps } from "solid-js";
import { Dynamic } from "solid-js/web";
import type { JSX } from "solid-js";
import { Properties as CSSProperties } from "csstype";
import validAttr from "@emotion/is-prop-valid";

export interface CSSAttribute extends CSSProperties {
  [key: string]: CSSAttribute | string | number | undefined;
}

interface IProps {
  class?: string;
  style?: Record<string, string>;
  classList?: any;
  as?: any;
}

type Tagged<T> = <P>(
  args_0:
    | string
    | TemplateStringsArray
    | CSSAttribute
    | ((
        props: P &
          T & {
            as?: string | number | symbol | undefined;
            class?: any;
            children?: any;
          }
      ) => string | CSSAttribute),
  ...args_1: (
    | string
    | number
    | ((
        props: P &
          T & {
            as?: string | number | symbol | undefined;
            class?: any;
            children?: any;
          }
      ) => string | number | CSSAttribute | undefined)
  )[]
) => ((props: P & T) => JSX.Element) & {
  class: (props: P & T) => string;
};

type Options = {
  name: string;
  class: string;
  atomic?: boolean;
  propsAsIs: boolean;
  vars?: {
    [key: string]: [
      string | number | ((props: unknown) => string | number),
      string | void
    ];
  };
};

const warnIfInvalid = (value: unknown, componentName: string) => {
  if (process.env.NODE_ENV !== "production") {
    if (
      typeof value === "string" ||
      (typeof value === "number" && isFinite(value))
    ) {
      return;
    }

    const stringified =
      typeof value === "object" ? JSON.stringify(value) : String(value);

    console.warn(
      `An interpolation evaluated to '${stringified}' in the component '${componentName}', which is probably a mistake. You should explicitly cast or transform the value to a string.`
    );
  }
};

export function filterProps(value: any): IProps {
  const newObject = {};

  const currentKeys = Object.keys(value);

  for (let i = 0, len = currentKeys.length; i < len; i += 1) {
    const key = currentKeys[i];

    if (validAttr(key)) {
      Object.defineProperty(newObject, key, {
        get() {
          return value[key];
        },
        configurable: true,
        enumerable: true,
      });
    }
  }

  return newObject as IProps;
}

export function pickProps<T extends Record<string, any>, K extends keyof T>(
  value: T,
  keys: K[]
): Pick<T, K> {
  const newObject = {};

  const currentKeys = Object.keys(value);

  for (let i = 0, len = currentKeys.length; i < len; i += 1) {
    const key = currentKeys[i];
    if (keys.includes(key as K)) {
      Object.defineProperty(newObject, key, {
        get() {
          return value[key];
        },
        configurable: true,
        enumerable: true,
      });
    }
  }

  return newObject as Pick<T, K>;
}

let idx = 0;

function styled(tag: any): any {
  let mockedClass = `mocked-styled-${idx++}`;

  if (tag?.__linaria?.className) {
    mockedClass += ` ${tag.__linaria.className}`;
  }

  return (opts: Options) => {
    const render = (props: any, ref: any) => {
      const [local, other] = splitProps(props, [
        "as",
        "class",
        "style",
        "classList",
      ]);
      const filteredProps = filterProps(other);
      const mergedProps = mergeProps(
        {
          component: local.as || tag,
          get ref() {
            return ref;
          },
          get class() {
            return opts.atomic
              ? cx(opts.class, local.class || mockedClass)
              : cx(local.class || mockedClass, opts.class);
          },
          get classList() {
            return local.classList;
          },
          get style() {
            const styles: { [key: string]: string } = {};

            if (opts.vars) {
              for (const name in opts.vars) {
                const variable = opts.vars[name];
                const result = variable[0];
                const unit = variable[1] || "";
                const value =
                  typeof result === "function" ? result(props) : result;

                warnIfInvalid(value, opts.name);

                styles[`--${name}`] = `${value}${unit}`;
              }

              const ownStyle = local.style || {};
              const keys = Object.keys(ownStyle);

              if (keys.length > 0) {
                keys.forEach((key) => {
                  styles[key] = ownStyle[key];
                });
              }
            }

            return styles;
          },
        },
        filteredProps
      );

      const Result = Dynamic(mergedProps);

      (Result as any).displayName = opts.name;
      (Result as any).__linaria = {
        className: opts.class || mockedClass,
        extends: tag,
      };

      return Result;
    };

    return render;
  };
}

export interface Styled {
  <T extends keyof JSX.IntrinsicElements>(
    tag: T | ((props: JSX.IntrinsicElements[T]) => JSX.Element)
  ): Tagged<JSX.IntrinsicElements[T]>;
  <T>(component: (props: T) => JSX.Element): Tagged<T>;
}

export default styled as Styled & {
  [Tag in keyof JSX.IntrinsicElements]: Tagged<JSX.IntrinsicElements[Tag]>;
};
